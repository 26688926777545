/* Chartist */

@mixin set-series-color($color) {

    .ct-point,
    .ct-line,
    .ct-bar,
    .ct-slice-donut {
        stroke: $color;
    }

    .ct-slice-pie,
    .ct-slice-donut-solid {
        fill: $color;
        stroke: #fff;
    }
}

// Styles
.ct-chart {
    .ct-label {
        font-size: 11px;
    }

    .ct-line {
        stroke-width: 2px;
    }

    .ct-point {
        stroke-width: 8px;
    }

    .ct-chart-pie,
    .ct-chart-donut {
        .ct-label {
            font-size: 16px;
            fill: rgba($body-text-inverse-color, 0.75);
        }
    }
}

.ct-chart {
    .ct-series-a {
        @include set-series-color(theme-color(primary));
    }

    .ct-series-b {
        @include set-series-color(theme-color(info));
    }

    .ct-series-c {
        @include set-series-color(theme-color(danger));
    }

    .ct-series-d {
        @include set-series-color(theme-color(success));
    }

    .ct-series-e {
        @include set-series-color(theme-color(warning));
    }
}