 /* Chart JS */

 #chartjs-tooltip {
     background: $card-bg;
     border-radius: 4px;
     padding: 10px;
     box-shadow: $chartjs-tooltip-shadow;
     transition: 0.3s ease;

     table {
         tr {
             th {
                 @include font-face($TYPE-2, 500);
                 @include ms-respond(font-size, -1);
                 letter-spacing: $letter-spacing;
                 color: $text-gray;
                 background: $card-bg;
             }

             td {
                 display: flex;
                 align-items: center;
                 @include font-face($TYPE-2, 500);
                 @include ms-respond(font-size, -2);
                 letter-spacing: $letter-spacing;
                 line-height: 1px;
                 padding-top: 5px;
                 padding-bottom: 5px;

                 &:first-letter {
                     text-transform: uppercase;
                 }

                 span {
                     width: 7px;
                     height: 7px;
                     display: inline-block;
                     border-radius: 100px;
                     margin-right: 7px;
                     box-shadow: $component-box-shadow;
                     color: $body-text-color;
                 }
             }
         }
     }
 }

 .chartjs-legend {
     ul {
         padding-left: 0;
         display: inline-flex;

         li {
             display: flex;
             align-items: center;
             flex-direction: row;
             margin-right: 15px;
             color: $text-gray;
             @include ms-respond(font-size, -2);
             @include font-face($TYPE-1, 400);
             letter-spacing: $letter-spacing;

             &:last-child {
                 margin-right: 0;
             }

             span {
                 @extend .status-indicator;
                 @extend .rounded-indicator;
                 @extend .small;
                 margin-right: 10px;
                 display: inline-block;
             }
         }
     }
 }