/* Data Table */

.dataTables_wrapper {
    table.dataTable {
        border-collapse: collapse !important;
        margin: 20px 0 40px 0 !important;

        thead,
        tfoot {
            tr {
                th {
                    border-bottom: none;
                }
            }
        }

        thead,
        tfoot {
            th {
                padding-right: 40px;

                &.sorting_asc,
                &.sorting_desc,
                &.sorting {
                    padding-right: 40px;
                }
            }
        }

        thead {
            background: transparent;

            tr {
                th:not(.sorting) {
                    border-bottom: $border-width $border-style $border-color;
                }

                .sorting_asc,
                .sorting_desc {
                    background: $table-accent-bg;
                    border-radius: 6px 6px 0 0px;
                    transition: 0.3s background;
                }

                th.sorting,
                .sorting_asc,
                .sorting_desc {

                    &::before,
                    &:after {
                        font-family: "Material Design Icons";
                        color: theme-color(primary);
                        font-size: 17px;
                        top: 10px;
                    }

                    &::before {
                        content: "\f360";
                    }

                    &::after {
                        content: "\f35d";
                    }
                }

                &.header-grouping-label {
                    th {
                        vertical-align: top;
                        border-bottom-width: 2px;
                        user-select: none;

                        &:nth-child(5n + 1) {
                            color: theme-color(primary);
                            border-bottom-color: theme-color(primary);
                        }

                        &:nth-child(5n + 2) {
                            color: theme-color(success);
                            border-bottom-color: theme-color(success);
                        }

                        &:nth-child(5n + 3) {
                            color: theme-color(warning);
                            border-bottom-color: theme-color(warning);
                        }

                        &:nth-child(5n + 4) {
                            color: theme-color(info);
                            border-bottom-color: theme-color(info);
                        }

                        &:nth-child(5n + 5) {
                            color: theme-color(danger);
                            border-bottom-color: theme-color(danger);
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    background: transparent;
                    transition: 0.3s ease background;

                    &.sorting_1 {
                        background: $table-accent-bg;
                    }
                }

                &:last-child {
                    td {
                        &.sorting_1 {
                            border-radius: 0 0px 6px 6px;
                        }
                    }
                }
            }
        }

        &[aria-describedby="vertical-scroll-table_info"] {
            margin: 0 !important;
        }
    }
}

.dataTables_scroll {
    max-width: 1050px;

    .dataTables_scrollBody {
        box-shadow: inset $dataTables-scroll-body-shadow;

        .dataTable {
            margin: 0 !important;
        }
    }
}