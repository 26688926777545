/*------------------------------------------------------------------
 [Master Stylesheet]

  Project:	Ripple UI
  Version:	1.4
-------------------------------------------------------------------*/

/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap Functions & Variables
  * Theme Variables
  * Bootstrap Main
  * Core Files And Mixins
  * Component Styles
    + Accordions
    + Alerts
    + Badges
    + Breadcrumbs
    + Buttons
    + Cards
    + Dropdowns
    + Checkbox Radio
    + Inputs
    + Kanban Board
    + Modals
    + Pagination
    + Progress Bars
    + Tabs
    + Tables
    + Timeline
  * Plugin Overrides
    + Switchery
    + Bootstrap Tag Input
    + Select 2
    + NoUi Slider
    + Datepicker
    + Summernote
    + Wizard
    + Jquery Toast
    + Full Calendar
    + Data Table
    + Chartist
    + C3 Charts
    + SimpleMDE
    + TinyMCE
    + Chart JS
  * Template
  * Utilities
  * Demo
  * Pages
    + Authentication Pages
    + Error Pages
    + Dashboard
    + Email
    + Calendar
    + Documentation

-------------------------------------------------------------------*/


/* === Bootstrap Functions & Variables === */
@import "../../../vendor/npm-asset/bootstrap/scss/functions";
@import "../../../vendor/npm-asset/bootstrap/scss/variables";
/*-------------------------------------------------------------------*/


/* === Theme Variables === */
@import "core/variables";
/*-------------------------------------------------------------------*/


/* === Bootstrap Main === */
@import "../../../vendor/npm-asset/bootstrap/scss/bootstrap";
/*-------------------------------------------------------------------*/


/* === Core Files And Mixins === */
@import "core/mixins/mixins";
@import "core/reset";
@import "core/fonts";
@import "core/spacing";
@import "core/typography";
@import "core/animation";
/*-------------------------------------------------------------------*/


/* === Component Styles === */
@import "components/accordion";
@import "components/alert";
@import "components/badge";
@import "components/breadcrumb";
@import "components/button";
@import "components/card";
@import "components/dropdown";
@import "components/checkbox-radio";
@import "components/input";
@import "components/kanban_board";
@import "components/modal";
@import "components/pagination";
@import "components/progress";
@import "components/tab";
@import "components/table";
@import "components/timeline";
/*-------------------------------------------------------------------*/


/* === Plugin Overrides === */
@import "components/plugin-overrides/switchery";
@import "components/plugin-overrides/bootstrap-tag-input";
@import "components/plugin-overrides/select2";
@import "components/plugin-overrides/no-ui-slider";
@import "components/plugin-overrides/datepicker";
@import "components/plugin-overrides/summernote";
@import "components/plugin-overrides/wizard";
@import "components/plugin-overrides/jquery-toast";
@import "components/plugin-overrides/full-calendar";
@import "components/plugin-overrides/data-table";
@import "components/plugin-overrides/chartist";
@import "components/plugin-overrides/c3-charts";
@import "components/plugin-overrides/simplemde";
@import "components/plugin-overrides/tinymce";
@import "components/plugin-overrides/chartjs";
/*-------------------------------------------------------------------*/


@import "template";
@import "utilities";
@import "demo";


/* === Pages === */
@import "pages/auth-theme";
@import "pages/error";
@import "pages/dashboard";
@import "pages/mail";
@import "pages/calendar-page";
@import "pages/docs";
/*-------------------------------------------------------------------*/

@import "../utm/utm";
