/* Error Pages */

.error_page {
    &.error_1 {
        display: flex;
        align-items: stretch;
        min-height: 100vh;
        background: url("http://www.placehold.it/1200x800") no-repeat 80% center;
        background-color: $card-bg;
        background-size: 45%;

        .inner-wrapper {
            display: flex;
            flex-direction: column;
            padding-top: 15%;

            .error-heading {
                font-weight: 600;
            }

            .error-code {
                margin-top: 10px;
                font-weight: 600;
            }

            .error-message {
                max-width: 500px;
                margin-top: 5px;
            }

            .btn {
                margin: 35px auto 0 0;
            }
        }
    }

    &.error_2 {
        display: flex;
        align-items: stretch;
        min-height: 100vh;
        background: $card-bg;

        .inner-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .error-heading {
                @include ms-respond(font-size, 20);
                font-weight: 600;
                line-height: 1;
            }

            .error-code {
                margin-top: 10px;
                font-weight: 600;
            }

            .error-message {
                max-width: 500px;
                margin-top: 5px;
                text-align: center;
            }

            .btn {
                margin: 30px auto 0 auto;
            }
        }
    }

    &.error_3 {
        padding: 3% 0 3%;
        min-height: 100vh;
        background: url("http://www.placehold.it/1200x800") no-repeat 80% center;
        background-size: 40%;
        background-color: $card-bg;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            background-size: contain;
            background-position: center 20%;
        }

        .inner-wrapper {

            .error-heading {
                margin-top: -20px;
                line-height: 1;
                font-weight: 600;
                @include ms-respond(font-size, 20);
            }

            .error-code {
                margin-top: 10px;
                font-weight: 600;
            }

            .error-message {
                max-width: 500px;
                margin-top: 5px;
            }

            .btn {
                margin: 30px 0 0 0;
            }
        }
    }
}

.lockscreen {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background: url("http://www.placehold.it/1200x800") no-repeat center;
    background-size: cover;
    background-color: $card-bg;
    z-index: 0;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }

    .profile-img {
        margin-bottom: 10px;
        border: 2px solid #fff;
    }

    .user_name {
        margin-bottom: 30px;
        color: #fff;
        @include font-face($TYPE-1, 600);
    }

    .form-wrapper {
        max-width: 300px;
        width: 100%;
        margin: 0 auto;

        .form-control {
            background: rgba(255, 255, 255, 0.5);
            border: none;
            color: #000;

            @include input-placeholder {
                font-weight: inherit;
                color: inherit;
            }
        }
    }
}