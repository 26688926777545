/* Select 2 */

.select2-container {
    max-width: 100%;

    .select2-selection {
        background: $input-bg;
        border: none;
        height: auto;

        .select2-selection__arrow {
            top: 50%;
            transform: translateY(-50%);
        }

        &.select2-selection--single {
            border: $border-width $border-style $input-border-color;
            height: auto;

            .select2-selection__arrow {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .select2-selection__rendered {
            background: $input-bg;
            padding: $input-btn-padding-x $input-btn-padding-y;
        }
    }

    .select2-dropdown {
        background: $input-bg;
        border: $border-width $border-style $input-border-color;

        .select2-search__field {
            border: $border-width $border-style $input-border-color;
            border-radius: $border-radius;
        }
    }
}