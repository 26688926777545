html,
body {
  height: 100%;
  font-size: 16px;
}

.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -60px;
  padding: 0 0 60px;
}

.wrap > .container {
  padding: 70px 15px 20px;
}

.footer {
  height: 60px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  color: #a4a8ac;
  background-color: #232c39;
}

.jumbotron {
  text-align: center;
  background-color: transparent;
}

.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

.not-set {
  color: #c55;
  font-style: italic;
}

.text-utm {
  color: #77c9bc;
}

.bg-utm {
  background-color: #77c9bc !important;
}

.btn-utm {
  color: #fff;
  background-color: #93c2ba;
  border-color: #77c9bc;
}

.btn-utm-light {
  border: 1px solid #77c9bc;
  background-color: #fff;
  color: #77c9bc;
}

.btn-utm.disabled {
  color: #B6BBC0;
  background-color: #E2E4E6;
  border-width: 2px;
  border-color: #B6BBC0;
}

.btn-utm-outline {
  border-color: #77c9bc;
  color: #77c9bc;
}

.detail-btn {
  width: 120px;
  -webkit-box-pack: start;
  justify-content: left;
  -webkit-box-align: start;
  align-items: start;

  & > i {
    width: 40px;
  }
}

a.utm-link {
  color: #77c9bc;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active {
  background-color: transparent;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}

a.asc:after {
  content: /*"\e113"*/
          "\e151";
}

a.desc:after {
  content: /*"\e114"*/
          "\e152";
}

.sort-numerical a.asc:after {
  content: "\e153";
}

.sort-numerical a.desc:after {
  content: "\e154";
}

.sort-ordinal a.asc:after {
  content: "\e155";
}

.sort-ordinal a.desc:after {
  content: "\e156";
}

.grid-view th {
  white-space: nowrap;
}

.hint-block {
  display: block;
  margin-top: 5px;
  color: #999;
}

.error-summary {
  color: #a94442;
  background: #fdf7f7;
  border-left: 3px solid #eed3d7;
  padding: 10px 20px;
  margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
  padding: 15px;
  border: none;
}

@media (max-width: 767px) {
  .nav li > form > button.logout {
    display: block;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
  }
}

.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
  text-decoration: none;
}

.nav > li > form > button.logout:focus {
  outline: none;
}

th a {
  color: #6c757d;
}

ul.breadcrumb li:before {
  display: inline-block;
  padding-right: .5rem;
  color: #6c757d;
  content: "/";
}

tr.hover-row td {
  cursor: pointer;
}

.navigation-menu li {
  max-width: 100%;
}

.break-word {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  /*word-wrap: break-word;*/
}

.required label:after {
  content: ' *';
  color: red;
}

.form-control, input[type=text], input[type=password], input[type=email], input[type=number], input[type=name], textarea {
  font-size: 16px;
}

.grid-view .card-title h5 {
  color: #77c9bc;
}

.kv-panel-before.btn-panel {
  position: absolute;
  top: 32px;
  right: 0;
  padding: 20px 12px;
}

.page-size {
  bottom: 10px;
  position: relative;
  padding: 5px 5px;
  margin-right: -5px !important;
}

.ga-panel {
  .card {
    border: 1px solid;
  }

  .sample-chart {
    height: 200px;
  }

  .ct-chart {
    //height: 200px;
  }
}

.utm-detail {
  .btn-panel {
    padding-top: 20px;
    justify-content: flex-end;
    display: flex;
  }


}

.table-info, .table-info > th, .table-info > td {
  background-color: transparent;
}

.table-info th, .table-info td, .table-info thead th, .table-info tbody + tbody {
  border: 0;
}

.kv-expand-detail-row,
.table-even tbody tr:nth-of-type(2n+1) {
  background-color: transparent !important;
}

.utm-detail {
  h5 {
    color: #77c9bc;
    padding-left: 15px;
  }
}

.info-header {
  position: absolute;
  top: -20px;
  display: inline-flex;
  justify-self: center;
}

.sidebar .navigation-menu li.active a .link-icon {
  &.shared {
    color: #f9fafb;
  }
}

.form-group .select2-container--krajee-bs4 .select2-selection {
  border: none;
  background-color: #f6f7f9;
  font-weight: 500;
  font-size: 16px;
  padding-left: 20px;

}

.form-group .select2-container--krajee-bs4 .select2-selection--single .select2-selection__placeholder {
  color: rgba(108, 117, 125, 0.6);
  font-family: "Roboto", sans-serif;
  letter-spacing: normal;
}

ul.discount {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  li {
    text-align: left;
  }
}
