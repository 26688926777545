/* Breadcrumbs */

@mixin breadcrumb-outlined-variant($color) {
  border: $border-width $border-style $color;

  a {
    color: $color;
  }
}

@each $color,
$value in $theme-colors {
  .breadcrumb.outlined-#{$color} {
    @include breadcrumb-outlined-variant(theme-color($color));
  }
}

.breadcrumb {
  background: $card-bg;

  &.has-arrow {
    .breadcrumb-item {
      &:before {
        font-family: "Material Design Icons";
        content: "\f142";
        vertical-align: inherit;
        font-weight: $font-weight-light;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }

  &[class*='bg'] {
    .breadcrumb-item {
      color: $body-text-inverse-color;

      &:before {
        color: inherit;
      }

      a {
        color: inherit;
      }
    }
  }

  &[class*='outlined'] {
    background: transparent;
  }
}

.breadcrumb-item {
  @include font-face($TYPE-1, 500);
  @include ms-respond(font-size, -1);
  letter-spacing: $letter-spacing;

  a {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    letter-spacing: inherit;
  }
}