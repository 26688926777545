/* Full Calendar */

.fc-button {
    @extend .btn;
    text-shadow: none;
    text-transform: capitalize;
}

.fc-state-default {
    @extend .btn-primary;
    background-image: none;
}

.fc-header-toolbar h2 {
    @extend .h5;
}

.fc-unthemed .fc-list-heading td.fc-widget-header {
    a {
        @include font-face($TYPE-1, 500);
    }
}

.fc-unthemed .fc-list-empty {
    background-color: $card-bg;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: url("http://www.placehold.it/1200x800") no-repeat center;
        background-size: 60%;
        opacity: 0.45;
    }
}

.fc-list-empty {
    color: $body-text-color;
    @include font-face($TYPE-1, 400);
    @include ms-respond(font-size, 2);
}

.fc-list-view {
    border-color: $border-color;
}

.fc-unthemed {
    tbody {
        border-color: $border-color;
    }

    td,
    th {
        border-color: darken($border-color, 4%);

        &.fc-today {
            background: lighten(theme-color(primary), 33%);
            color: theme-color(primary);
        }

    }

    .fc-list-view {
        border-color: $border-color;
    }

    .fc-list-item {
        border-color: $border-color;

        &:hover {
            td {
                background-color: lighten($card-bg, 4%);
            }
        }
    }

    .fc-list-heading {
        .fc-widget-header {
            border-color: $border-color;
        }
    }

    .fc-view-container {
        .fc-basic-view {
            .fc-day-header {
                padding: 10px;
            }

            .fc-row .fc-content-skeleton td {
                border-color: darken($border-color, 4%);

                &.fc-day-top {
                    padding: 0px 10px;
                    @include font-face($TYPE-1, 400);
                    @include ms-respond(font-size, 0);
                    color: $body-text-color;
                    background: $body-accent-color;
                    border-right: 1px solid darken($border-color, 4%);

                    &.fc-past {
                        background: $body-accent-color;
                        color: $text-muted;
                        opacity: 1;
                    }

                    &.fc-future {
                        background: $body-accent-color;
                        opacity: 1;
                    }

                    &.fc-other-month {
                        color: $text-muted;
                    }

                    &.fc-today {
                        background: lighten(theme-color(primary), 33%);
                        color: theme-color(primary);
                    }
                }
            }

            .fc-popover {
                .fc-widget-header {
                    padding: 5px 10px;
                }

                .fc-widget-content {

                    .fc-day-grid-event {
                        color: $body-accent-color;
                        margin-bottom: 5px;

                        .fc-content {
                            padding: 5px 5px;

                            .event-invers-danger {
                                @extend .bg-inverse-danger;
                            }
                        }
                    }
                }
            }

            .fc-content-skeleton {
                .fc-event-container {
                    .fc-h-event {
                        border: none;
                        padding: 10px 25px;
                        border-radius: 0px;
                        height: 35px;
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            height: 100%;
                            width: 3px;
                        }

                        .fc-content {
                            display: flex;
                            flex-direction: column-reverse;
                            margin-top: 5px;

                            .fc-title {
                                font-size: 12px;
                                font-weight: 500;
                            }

                            .fc-time {
                                font-size: 10px;
                                font-weight: 300;
                            }
                        }

                        &.event-invers-danger {
                            &:before {
                                content: "";
                                background-color: theme-color(danger) !important;
                            }

                            background-color: rgba(theme-color(danger), 0.3) !important;
                            color: theme-color(danger);
                        }

                        &.event-invers-primary {
                            &:before {
                                content: "";
                                background-color: theme-color(primary) !important;
                            }

                            background-color: rgba(theme-color(primary), 0.3) !important;
                            color: theme-color(primary);

                        }

                        &.event-invers-success {
                            &:before {
                                content: "";
                                background-color: theme-color(success) !important;
                            }

                            background-color: rgba(theme-color(success), 0.3) !important;
                            color: theme-color(success);
                        }

                        &.event-invers-warning {
                            &:before {
                                content: "";
                                background-color: theme-color(warning) !important;
                            }

                            background-color: rgba(theme-color(warning), 0.08) !important;
                            color: theme-color(warning);
                        }

                        &.event-invers-info {
                            &:before {
                                content: "";
                                background-color: theme-color(info) !important;
                            }

                            background-color: rgba(theme-color(info), 0.3) !important;
                            color: theme-color(info);
                        }
                    }
                }

                .fc-more-cell {
                    font-weight: 600;
                    color: $body-accent-text-color;
                    padding: 0 7px;
                }
            }
        }
    }
}