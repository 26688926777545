/* NoUi Slider */

@each $color,
$value in $theme-colors {
  .slider-#{$color} {
    @include slider-color-variant($value);
  }
}

.ul-slider {
  border: none;
  box-shadow: none;
  background: rgba($body-accent-color, 0.8);

  .noUi-handle {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    box-shadow: $component-box-shadow;
    border: none;

    &:before,
    &:after {
      display: none;
    }
  }

  &.noUi-horizontal {
    height: 0.5rem;

    .noUi-handle {
      top: 50%;
      transform: translateY(-36%);
    }
  }

  &.noUi-vertical {
    width: 0.5rem;
    height: 200px;
    display: inline-block;
  }
}