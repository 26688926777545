/* Tabs */

@mixin tab-style-variations($color) {

    .nav-tabs,
    .nav-pills {
        .nav-item {
            .nav-link {
                color: $color;

                i {
                    color: inherit;
                }

                &.active {
                    background: $color;
                    color: $body-text-inverse-color;
                }
            }
        }
    }

    .tab-content {
        background: $color;
        color: $body-text-inverse-color;
    }
}

@each $color,
$value in $theme-colors {
    .tab-container.tab-bg-#{$color} {
        @include tab-style-variations($value);
    }
}

.nav-tabs {
    border-bottom: $border-width $border-style $border-color;
}

.tab-container {

    .nav-tabs,
    .nav-pills {
        border-bottom: none;

        .nav-item {
            .nav-link {
                display: flex;
                align-items: center;
                justify-content: start;
                @include ms-respond(font-size, -2);
                @include font-face($TYPE-1, 500);
                letter-spacing: 1;
                padding: 7px 13px;
                border: none;
                transition-duration: 0.3s;
                transition-property: "background", "color";

                i {
                    font-size: 20px;
                    line-height: 1;
                }

                .tab-tittle {
                    display: inline-block;

                    &.prepend {
                        margin-left: 10px;
                    }

                    &.append {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .tab-content {
        padding: 30px 20px 20px 20px;
        width: 100%;
        background: darken($template-body-color, 3%);
    }

    .nav-tabs {
        .nav-link {
            color: $body-text-color;

            i {
                color: $body-text-color;
            }

            &.active {
                background: darken($body-accent-color, 3%);
                color: theme-color(primary);

                i {
                    color: inherit;
                }
            }
        }
    }

    .nav-pills {
        margin: 10px;

        .nav-link {
            &.active {
                box-shadow: $component-box-shadow;
                color: $body-text-inverse-color;
            }
        }
    }

    &.vertical-tabs {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        .nav-tabs,
        .nav-pills {
            float: left;
            display: flex;
            flex-direction: column;
        }
    }
}