/* Dashboard */

#current-circle-progress {
    position: relative;

    canvas {
        margin: auto;
        display: block;
    }

    .circle-progress-value {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: auto;
        text-align: center;
    }
}

.deposit-balance-card {
    .deposit-balance-card-footer {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-top: 10px;
        margin-top: 24px;
        border-top: 1px solid $border-color;

        .footer-col {
            display: flex;
            flex-direction: column;
            border-right: 1px solid $border-color;

            &:last-child {
                border-right: none;
            }
        }
    }
}

.dashboard-timeline {
    max-height: 250px;
    overflow-y: auto;
}