/* Modals */

.modal-dialog {
  .modal-content {
    border: none;
    background: $card-bg;
    border-radius: $border-radius;
    box-shadow: $component-box-shadow;
  }
}

.modal-header {
  border-bottom: none;
  border-radius: $border-radius $border-radius 0 0;
  padding: 20px 20px;

  .modal-title {
    @include ms-respond(font-size, -1);
    color: $body-text-color;
  }
}

.modal-body {
  color: $text-muted;
  padding: 30px 20px 10px 20px;
  color: $text-gray;
}

.modal-footer {
  border-top: none;
  padding: 20px 20px;
}